import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';
// import mailIcon from '../../assets/img/mailg.png';
// import greenarr from '../../assets/img/greenarr.png';
import closeImg from '../../assets/img/close.png';
import gmailImg from '../../assets/img/gmail.png';
import teamsImg from '../../assets/img/teams.png';
import waltAgius from '../../assets/img/walln.png';
import davidP from '../../assets/img/david.png';
import davidJ from '../../assets/img/davidf.png';
import steve from '../../assets/img/steve.png';
import imelda from '../../assets/img/imelda.png';
import anthony from '../../assets/img/anthony.png';
import traci from '../../assets/img/tr.png';
import jackie from '../../assets/img/jackie.png';
import robort from '../../assets/img/robort.png';
import john from '../../assets/img/john.png';
import franco from '../../assets/img/franco.png';
import bianca from '../../assets/img/bianca.png';
import allison from '../../assets/img/allison.png';
import glarr from '../../assets/img/glarr.png';
import teambgImg from '../../assets/img/teambg.png';
import { Helmet } from "react-helmet";

function Team() {

    const [show, setShow] = useState(false);
    const [member, setMember] = useState({});
    const handleClose = () => setShow(false);
    const showProfile = (user) => {
        setMember(user);
        setShow(true);
    }
    const teams = [{
        name: "Walt Agius",
        role: "Owner/Chairman",
        image: waltAgius,
        email: "wagius@lendgistics.com",
        info: "<div class='fs14'>Walt Agius is the founder and Managing Member/Board Chair of Lendgistics, LLC (dba CU Lending Edge) and also the founder and CEO of a FinTech Loan Origination software platform called Lendsys.</div><div class='fs14 mt-3'>Walt has over 30 years of experience as CEO, along with being a Consultant working with and for credit unions, finance companies, CUSO’s, auto dealerships, and developed multiple successful Entrepreneurship companies. He continues to be a frequent speaker and presenter for National Financial Marketplace related conferences.</div>"
    }, { 
        name: "David Frankil",
        role: "Owner and Chief Strategy Officer",
        image: davidJ,
        email: "dcfrankil@gmail.com",
        info: "<div class='fs14'>David has a diverse, forty year career spanning C-level positions at companies and associations, management consulting and investment banking, venture-funded startups and government affairs at the national level.</div><div class='fs14 mt-3'>David has been President, CrossState Solutions, the for-profit subsidiary of the CrossState Credit Union Association; Pres/CEO of the New Jersey Credit Union League the State association for credit unions in New Jersey; President, Burns-Fazzi, Brock & Assoc (now part of Gallagher Benefits) a firm specializing in executive benefits for credit union C-level executives; and President, NAFCU Services Corp, the for-profit subsidiary of the National Association of Federally Insured Credit Unions.</div><div class='fs14 mt-3'>Prior to that, David helped companies of all sizes as a management consult and investment banker as Managing Director–Washington DC for Gates & Company; founded and ran a venture-funded startup called Virtual Compliance; and had a full career in government affairs at the national level with the National Association of Manufacturers, the American Furniture Manufacturers Association, and Champion International Corp (now part of International Paper). David is a Wharton MBA. David lives in New Jersey about fifty yards from the beach with his wife Allison and rescue dog Pokey, and in his spare time enjoys cycling along the coast, playing bridge and chess and building way-too-complicated spreadsheets.</div>"
    }, { 
        name: "Steve Ryerson",
        role: "Owner and Chief Revenue Officer",
        image: steve,
        email: "sryerson@sjr4advisors.com",
        info: "<div class='fs14'>Steve is a Financial Services executive with proven leadership and entrepreneurial experience. He has over 40 years’ experience as a C-Suite leader with very significant responsibilities and oversight for P & L’s, revenue generation, product creation, global partnership development, market share and acquisitions. Steve spent the early part of his career on Wall St. where for over 20+ years he had C-Level roles at several firms. His experience spans institutional banking and finance as well as government securities trading and brokering. After a successful career on Wall St., Steve pivoted to the retail financial services sector and joined the United Nations Federal Credit Union (UNFCU) in 2003.</div><div class='fs14 mt-3'>Steve served UNFCU for almost 16 years where he held the roles of President & CEO of UNFCU Financial Services and UNFCU Advisors and as UNFCU’s Chief Business Development Officer. Steve grew UNFCU’s non-banking financial services platform to serve United Nations staff and their families around the globe.</div><div class='fs14 mt-3'> In 2019, after 16 years of service at the United Nations Federal Credit Union, Steve founded SJR IV Advisors to fulfill his vision and passion to help financial service companies grow revenue and profits by helping them deliver value driven financial products and services to the clients and members they serve. </div><div class='fs14 mt-3'> In 2021, seeing a strategic fit with Lendgistics, Steve purchased a significant equity stake in the company and assume the role of Chief Revenue and Business Development Officer.</div>"
    }, { 
        name: "David Berver",
        role: "SVP of Systems",
        image: davidP,
        email: "dberver@lendgistics.com",
        info: "<div class='fs14'>Extensive Credit Union background in all areas of operations, but primarily in lending. David’s 20 years plus of Credit Union experience covers managing Branch operations, along with responsibilities of developing and managing an Auto Dealership CUSO. He has an extensive background in all facets of indirect and direct lending, from underwriting to program software design.  His full range of experience and background brings both the lender and dealer’s perspective.</div><div class='fs14 mt-3'>Enjoys long walks on the beach, if it’s not sandy.  Enjoys to go to Disney, when there are no long lines.  Enjoys Summer, so long as there is air conditioning.  Enjoys to put on English subtitles, on English speaking movies.  Enjoys doing meditation, when he’s sleeping.</div>"
    }, { 
        name: "Anthony Artico",
        role: "SVP of Operations",
        image: anthony,
        email: "Anthonya@lendgistics.com",
        info: "<div class='fs14'><b>Professional Bio:</b></div><div class='fs14 mt-3'>10 + years’ Experience as a Lending & Collections Manager with a demonstrated history of working in the Financial Services industry. Skilled in Banking, Credit Unions, Loans, Account Management, Operations, and Customer Service. Strong finance professional with an Associate's Degree focused in Criminal Justice/Law Enforcement Administration from Henry Ford College. I also served on the board of directors for the Allen Park Hockey Association as their Treasurer from April 2017-April 2019 as well as Co-Chair for the Downriver Risk Management Committee from January 2017- July 2022.</div><div class='fs14'><b>Personal Bio:</b></div><div class='fs14 mt-3'>I have been married 10 years to My wife Jessica, we have two boys together. Connor age 14 & Dominic age 8. We love sports in our house as both of my boys play hockey. My youngest Dominic plays baseball as well. In my spare time I love to play golf, fish, travel, and spend time with family and friends. My favorite sport is football even though my boys play hockey, and my favorite food is Pizza. One thing people may not know about me is I am a great cook and enjoy trying new recipes.</div>"
    }, { 
        name: "Imelda Siocheng",
        role: "VP of Home Operations",
        image: imelda,
        email: "imeldag@lendgistics.com",
        info: "<div class='fs14'><b>Professional Facts about me:</b> </div><div class='fs14 mt-2'>I have 20+ years of experience in Lending (Direct/Indirect Consumer Lending and HELOC) and Member Service.</div><div class='fs14 mt-2'>I have a Bachelor Degree in Business Management from Centro Escolar University (Manila, Philippines).  </div><div class='fs14 mt-2'>I take pride in being resourceful and adaptable to the day to day market conditions. I have been lucky enough to work and learn from some of the best minds in the industry. </div><div class='fs14 mt-2'>I’m a firm believer in work-life balance, and team building activities. </div><div class='fs14 mt-3'><b>Personal Facts about me: </b> </div><div class='fs14 mt-2'>I’ve been happily married since 2010 to another kindred spirt, life traveler…penny pincher extraordinaire. </div><div class='fs14 mt-2'>I have one pet guinea pig named “Peanut” and two beautiful daughters. </div><div class='fs14 mt-2'>I love cooking, baking, BBQ and trying new recipes. </div><div class='fs14 mt-2'>I love family activities and spending time doing what I love most…”being a foodie”. </div><div class='fs14 mt-2'><b>“Coming together is a beginning, keeping together is a progress, working together is a success.” – Henry Ford</b> </div>"
    }, { 
        name: "Traci Stovall-Johnson",
        role: "VP of Quality Control",
        image: traci,
        email: "traci@lendgistics.com",
        info: "<div class='fs14'>Traci is a 30 year financial professional with a broad range of skills, experience, education, and training. She has substantial experience in both mortgage lending and consumer lending, as well as a background in training, customer service management, marketing management, and strategic planning. Traci was previously employed as Director of Training and Recruiting for Liberty’s Credit Union Outsource Marketing Division. In this position she managed a small team of marketing directors, trained incoming marketing directors, and developed marketing plans and budgets for Southern California clients. After Liberty downsized their California operations, Traci moved on to run her own MAC* award-winning credit union marketing business. Traci eventually returned to her lending roots by joining the Lendgistics Indirect Lending Team several years ago. She is currently working in both the Home Improvement Lending and the Indirect Lending Departments. As VP of Quality Control, Traci oversees the funding department, and the development and implementation of procedures to minimize errors, as well as policies that facilitate accuracy and quality from start to finish.</div><div class='fs14 mt-3'>Traci is committed to helping our clients meet their loan growth goals, and to ensuring that we provide quality service to our customers and partners, while producing a quality funded loan for each approved and completed application.</div><div class='fs14 mt-3'>Traci is married with two sons.  She loves to cook, is an avid reader and a musician who enjoys singing and playing classical piano.</div><div class='fs14 mt-3'><b>Personal Favorite Quotes:</b></div><div class='fs14 mt-3'><b>“Never be limited by other people’s limited imaginations.” — Mae Jemison</b></div><div class='fs14 mt-3'><b>*MAC - Marketing Association of Credit Unions</b></div>"
    }, { 
        name: "Jackie Laurain",
        role: "Director of Indirect",
        image: jackie,
        email: "jackiel@lendgistics.com",
        info: "<div class='fs14'>I made the switch to CULE after spending 8+ years as a Branch Manager at a Credit Union. I started there as a part-time teller with no experience and within a year I moved up the chain and became Branch Manager. I was over the entire operations department including the frontline & lending department. I spent 6 years on the outreach committee for the chapter of credit unions working with all of the Michigan Credit Unions and putting on tons of dinners, events & benefits for all of the staff, board of directors & CEOs of each credit union. Before transferring over to the credit union, I was a server for 15 years and really came accustom to the member service side of things. I truly enjoy working with the public & meeting new people. I am a very hard worker, very driven & always willing to learn! </div><div class='fs14 mt-2'>I have been with my husband, Doug for 6 years and married almost 2. We love going out to eat and having date nights but I also really enjoy to cook and challenge myself in the kitchen. Although I do love to cook, my favorite food is actually pickles! I've loved pickles since I can remember and I am known for my love of them! I was a cheerleader all through middle & high school and having that experience has definitely stuck with me, I believe I will always been a natural cheerleader and constantly cheer everyone on! I moved to Ft. Lauderdale on a whim when I was 23 and spent 2 years there. I have tons of neices and nephews that I love to spend time with and spoil!  I try to live my life on a very positive note and always look at the glass half full!</div>"
    }, { 
        name: "Johnathan Madrid",
        role: "Validation",
        image: john,
        email: "jonathanm@lendgistics.com",
        info: "<div class='fs14'>I’m a young man who enjoys mountain biking, hiking, computer building, and e-sports. I enjoy exploring the world from new heights and unraveling the beauties within it. One of my many fortes is to build computers from scratch while I relax my night away. My previous career was focused mainly in construction, hardscaping, and lumber. From that industry, I gained multiple skills such as management and customer service which became valuable with my current career. I have been blessed that I was given an opportunity to join this team as I learn more about credit, underwriting, ratios and validation.</div><div class='fs14 mt-3'>My goal is to “Never stop learning, Because life never stops teaching.”</div>"
    }, { 
        name: "Robert Guirola",
        role: "Boarding/Indirect Auto",
        image: robort,
        email: "robertg@lendgistics.com",
        info: "<div class='fs14'>I have extensive work experience with Call Center industry for 20+ years. I have gained invaluable strong work ethics in Customer Service, Sales, Account Administration, Team Building and Troubleshooting. </div><div class='fs14 mt-3'>I have learned that “To give real service you must add something which cannot be bought or measured with money, and that is sincerity and integrity”. </div><div class='fs14 mt-3'>I am a Certified Red Cross Volunteer. In case of major disaster such as earthquake, hurricane, flooding or landslides, I am trained to assist as First Responder with National Relief Agencies. </div><div class='fs14 mt-3'>I enjoy playing chess at an amateur level to exercise my brain. One of my hobby is to repair, build and/or design computers.</div>"
    }, { 
        name: "Franco Daniel",
        role: "Validation",
        image: franco,
        email: "fdaniel@lendgistics.com",
        info: "<div class='fs14'><b>Professional Bio:</b></div><div class='fs14 mt-2'>Started in the IT world 7 years ago as an External Consultant for a Real State Company as a QA manual tester.</div><div class='fs14 mt-2'>Developed his skills in the Project Managing and Costumer Service, handled as a chief PM to 5 projects at the same time with a group of work of 12 persons.</div><div class='fs14 mt-3'><b>Personal Bio:</b></div><div class='fs14 mt-3'>Franco D. Pumarino Villalba started to work at a young age (15 Years Old) in Costumer Service in Multiple Areas.</div><div class='fs14 mt-2'>Animal Rescuer, Nature Lover, Drummer, Dancer and Multi-faceted.</div><div class='fs14 mt-3'><b>Personal Favorite Quotes:</b></div><div class='fs14 mt-3'><b>“Believe you can and you're halfway there.”</b></div><div class='fs14 mt-3'><b>“Whenever you are asked if you can do a job, tell 'em, 'Certainly I can!' Then get busy and find out how to do it.”</b></div>"
    }, { 
        name: "Allison Vargas",
        role: "Boarding",
        image: allison,
        email: "avargas@lengistics.com",
        info: "<div class='fs14'>I was born on October 4th, 1978 in Tomas Frías, Potosí – Bolivia. My father was Ernesto Vargas Acebey, an accountant by profession and Olympic Instructor of Physical Bodybuilding, who passed away in 2013, my mother is Jenny Rúa Campero, a secretary by profession. I have three siblings, the eldest is called Henri and my two sisters, Sheila and Milenka.</div><div class='fs14 mt-3'>My studies were carried out at the Co-Educational College Antofagasta, later at the Autonomous University of Tomas Frías in the auditing career, my studies continued at the Pascal Institute with the training of Medium Computer Technician, secretary, youth counselor against Tobacco, Alcoholism and drug addiction, studies completed in QA Software Testing Course - Basic in QA Academy, participated in the Computer Olympics of the Santo Tomas de Aquino La Paz University, I was as a support staff in G.A.C.I.P as a Youth counselor, I worked in Teaching as a substitute teacher of multigrade. I worked in a law firm as a secretary, later I worked in a publishing house as a regional manager for 12 years, I am a member of the APA society in Potosí supporting book fairs with the dissemination and exhibition of books in literature in general with Authors from Potosí. I married in 2000 and divorced in 2002. I have a 22-year-old daughter. I married for the second time in December 2017, unfortunately my husband passed away in June 2020, I currently work at SoftSmart Corporations.</div><div class='fs14 mt-3'>Life was not particularly easy, but I have two very important reasons in my life, my mother and my daughter are all I have to look forward.</div>"
    }]

    return(
        <>
            <Helmet>
                <title>Teams - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'teams'}></Menu>
            <div>
                <img src={teamsImg} alt="Teams" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>OUR TEAM</h3>
                    </div>
                    <div className='teams-member mt-5'>
                        <div className="row">
                            {
                                teams.map((user, key) => {
                                    return <div key={key} className='col-md-3 text-center mb-5'>
                                        <div className='bg-image' style={{backgroundImage: "url("+user.image+")"}}>

                                        </div>
                                        {/* <img src={user.image} alt={user.name} className='img-fluid about-user' /> */}
                                        <h3 className='clrbl inter-bold mt-2'>{user.name}</h3>
                                        <div className='clrgr d-block'>{user.role}</div>
                                        <div className='mt-2'>
                                            <div className='text-uppercase clrlg text-none view-profile pointer' onClick={() => showProfile(user)}> Profile <img src={glarr} alt="Walt Agius" className='img-fluid' /></div>
                                        </div>
                                    </div>
                                })
                            }
                            {/* {
                                teams.map((user, key) => {
                                    return <div key={key} className="col-md-4 mt-2 mb-4">
                                        <div className='position-relative'>
                                            <div className="img-team">
                                                <img src={user.image} alt={user.name} className='img-fluid w-100' />
                                            </div>
                                            <div className="team-info">
                                                <span className='text-white'>{user.role}</span>
                                                <div className='text-white t-name inter-bold'>{user.name}</div>
                                                <div className='text-white t-email text-end mt-1'><img src={mailIcon} alt={'Email'} height={12} className="me-2" />{user.email}</div>
                                                <div className='full-link mt-3 d-block inter-bold pointer' onClick={() => showProfile(user)}>Full Profile <img src={greenarr} alt={'View'} height={28} className="me-2" /></div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            } */}
                        </div>
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>    
                </div>
            </div>
            <Footer></Footer>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size="xl"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-4'>
                            <img src={member.image} alt={member.name} className='img-fluid' />
                        </div>
                        <div className='col-md-8 position-relartive'>
                            <div className='clrbl text-uppercase fs14 pointer close-profile' onClick={handleClose}><b>Close Profile</b> <img src={closeImg} alt='Close' height={24} /></div>
                            <div className='clrgr fs14'>{member.role}</div>
                            <h4 className='clrbl'>{member.name}</h4>
                            <div className='clebl' dangerouslySetInnerHTML={{__html: member.info}}></div>
                            <div className='mt-3 fs14'>
                                <img src={gmailImg} alt='Close' height={16} /> {member.email}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Team;