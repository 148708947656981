import React, { useState } from 'react'
import logo from "../../logo.png"
import "./ListContact.css"
import axios from 'axios'
import { addAuthToHeader } from './../../shared/AuthHelper';

const ListContact = () => {
    const [password,setPassword] = useState("");
    const [truePassword,setTruePassword] = useState(false)
    const [listContact,setListContact] = useState([]);
    const contraseña = "123456789"

    const getListContact = async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/Models/List`,
        {
            Id:0,
            Model: "ContactUs",
            Extensions: null,
            Params: []
        },
        {
            headers:{
                Authorization: `Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`
            }
        })
        if(res.data.data.ListInfo){
            setListContact(res.data.data.ListInfo)
            console.log("ListContact Called Successfully")
        } else{
            console.log("Something went wrong")
        }
      } catch (error) {
        console.log(error)
      }
    }

    const prueba = () => {
        if(password === contraseña){
            setTruePassword(true)
            getListContact();
        }else{
            setTruePassword(false)
        }
    }

  return (
    <div className='list-container'>
        <div className='header'>
            <div className='image-logo'>
                <img src={logo} alt="logo" />
            </div>
            <div className='password-input'>
                <label htmlFor="password" style={{color:"white",marginRight:"5px"}}>Password:</label>
                <input id="password" type='password' style={{marginRight:"10px",outline:"none",borderRadius:"5px",border:"none",padding:"5px"}} value={password} onChange={(e) => setPassword(e.target.value)}/>
            </div>
        </div>
        <div className='list-body'>
            <div style={{display:"flex",justifyContent:"end"}}>
            <button className='button' onClick={prueba}>Refresh</button>
            </div>
            <div style={{display:"flex",justifyContent:"center",marginTop:"30px",padding:"8px"}}>
                {truePassword ? (
                     <table className='list-table'>
                     <thead>
                         <tr>
                             <th className='th-list'>Id</th>
                             <th className='th-list'>Fullname</th>
                             <th className='th-list'>Email</th>
                             <th className='th-list'>Phone</th>
                             <th className='th-list'>Subject</th>
                             <th className='th-list'>Date</th>
                             <th className='th-list2'>Message</th>
                         </tr>
                     </thead>
                     <tbody>
                         {listContact && listContact.length !== 0 ? (
                            listContact.map((list,index) => (
                                <tr className='list-table' key={index}>
                                <td className='th-list'>{list.Id}</td>
                                <td className='th-list'>{list.Fullname}</td>
                                <td className='th-list'>{list.Email}</td>
                                <td className='th-list'>{list.Phone}</td>
                                <td className='th-list'>{list.Subject}</td>
                                <td className='th-list'>{list.ChangedOn}</td>
                                <td className='th-list2'>{list.Message}</td>
                            </tr>
                            ))
                         ) : 
                         (null)}
                     </tbody>
                 </table>
                ) : null}     
            </div>
        </div>
    </div>
  )
}

export default ListContact