import React from 'react';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';
import technoImg from '../../assets/img/techno.png';
import techImg from '../../assets/img/tech.png';
import technologyImg from '../../assets/img/technology.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import checkImg from '../../assets/img/check.png';
import bankImg from '../../assets/img/bank.png';
import barsImg from '../../assets/img/bars.png';
import dollorImg from '../../assets/img/doller.png';
import teambgImg from '../../assets/img/techbg.png';
import { Helmet } from "react-helmet";

function Technology() {

    return(
        <>
            <Helmet>
                <title>Technology - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'technology'}></Menu>
            <div>
                <img src={technoImg} alt="Technology" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>Technology</h3>
                    </div>
                    <div className='about-us'>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className='clrbl d-flex'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    The Lendgistics technology platform is a proven, high efficiency SAAS consumer loan origination and decisioning system that manages the entire loan sourcing channel, from sourcing to processing, underwriting and integration with third party services.  Originally developed for indirect auto markets, the technology is flexible, granular, customizable and scalable for all consumer asset classes.
                                </div>
                                <div className='clrbl d-flex mt-3'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    Featuring open APIs for applications, back-office processing, packaging, and validation, the platform is easily integrated with both loan sources and lenders.
                                </div>
                                <div className='clrbl d-flex mt-3'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    For loan sources, application information is seamlessly passed to Lendgistics, allowing for unparalleled efficiency in processing loan applications and delivering decisions.
                                </div>
                            </div>
                            <div className='col-md-6 position-relative'>
                                <img src={blankline} alt="About Us" className='first-blank' />
                                <img src={greenline} alt="About Us" className='first-green' />
                                <img src={techImg} alt="Lenders"  className='img-fluid'/>
                                <img src={greenline} alt="About Us" className='last-green' />
                                <img src={blankline} alt="About Us" className='last-blank' />
                            </div>
                        </div>
                    </div>
                    <div className='about-us'>
                        <div className="row">
                            <div className='col-md-6 position-relative'>
                                <img src={blankline} alt="Technology" className='left-first-blank' />
                                <img src={greenline} alt="Technology" className='left-first-green' />
                                <img src={technologyImg} alt="Technology"  className='img-fluid'/>
                                <img src={greenline} alt="Technology" className='left-last-green' />
                                <img src={blankline} alt="Technology" className='left-last-blank' />
                                
                            </div>
                            <div className='col-md-6'>
                                <div className='clrbl d-flex'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    For lenders, the technology acts as a true extension of their internal lending team, with underwriting criteria custom developed for the desired vertical and mix of loans desired. Once the buy box has been set, the lender is assured of receiving only those loans that meet their criteria from any given channel. Lenders connect to the platform once and access multiple consumer loan verticals like home improvement, auto and elective medical/dental loans. 
                                </div>
                                <div className='clrbl d-flex mt-3'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    The technology can integrate directly with lender systems or more frequently directly to third-party servicers for a total outsource model.  Flexibility and scalability enables maximum efficiencies of scale in processing of loans.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className='col-md-4 mt-5'>
                            <div className='custom-card text-center'>
                                <img src={bankImg} alt="Technology" className='img-fulid' height={75} />
                                <h3 className='inter-bold clrbl mt-4'>24/7 EFFICIENCY</h3>
                                <div className='gr-line'></div>
                                <div className='inter clrbl mt-3'>
                                    Manages loan sourcing channels with a minimum of effort
                                </div>
                            </div> 
                        </div>
                        <div className='col-md-4 mt-5'>
                            <div className='custom-card text-center'>
                                <img src={barsImg} alt="Technology" className='img-fulid' height={75} />
                                <h3 className='inter-bold clrbl mt-4'>ROBUST DECISIONING</h3>
                                <div className='gr-line'></div>
                                <div className='inter clrbl mt-3'>
                                    Infinitely customizable to build buy boxes to desired specifications
                                </div>
                            </div> 
                        </div>
                        <div className='col-md-4 mt-5'>
                            <div className='custom-card text-center'>
                                <img src={dollorImg} alt="Technology" className='img-fulid' height={75} />
                                <h3 className='inter-bold clrbl mt-4'>AUTOMATION</h3>
                                <div className='gr-line'></div>
                                <div className='inter clrbl mt-3'>
                                    Technology and open APIs allow for unparalleled efficiency in processing
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Technology;