import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../logo.png';
import { Link } from 'react-router-dom';

function Menu({ menuName }) {
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='container position-relative'>
            <div className='top-menus w-100'>
                <img className='logo' src={logo} alt="logo" />
                <Navbar className='nav-custom w-100 p-0 mt-3' expand="lg">
                    <Container className='p-0'>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link className={menuName === 'home' ? 'nav-link active': 'nav-link'} as={Link} to={'/home'}>Home</Nav.Link>
                                <Nav.Link className={menuName === 'vertical' ? 'nav-link active': 'nav-link'} as={Link} to={'/vertical'}>Vertical</Nav.Link>
                                <Nav.Link className={menuName === 'lenders' ? 'nav-link active': 'nav-link'} as={Link} to={'/lenders'}>Lenders</Nav.Link>
                                <Nav.Link className={menuName === 'fintechs' ? 'nav-link active': 'nav-link'} as={Link} to={'/fintechs'}>FinTechs</Nav.Link>
                                <Nav.Link className={menuName === 'buyers' ? 'nav-link active': 'nav-link'} as={Link} to={'/buyers'}>Buyers</Nav.Link>
                                <Nav.Link className={menuName === 'technology' ? 'nav-link active': 'nav-link'} as={Link} to={'/technology'}>Technology</Nav.Link>
                                <Nav.Link className={menuName === 'teams' ? 'nav-link active': 'nav-link'} as={Link} to={'/teams'}>Team</Nav.Link>
                                <Nav.Link className={menuName === 'about' ? 'nav-link active': 'nav-link'} as={Link} to={'/about'}>About Us</Nav.Link>
                                <Nav.Link className={menuName === 'careers' ? 'nav-link active': 'nav-link'} as={Link} to={'/careers'}>Careers</Nav.Link>
                                <Nav.Link className={menuName === 'contact' ? 'nav-link active': 'nav-link'} as={Link} to={'/contact'}>Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </div>
    )
}

export default Menu;