import React from 'react';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';
import finImg from '../../assets/img/fin.png';
import fintechImg from '../../assets/img/fintech.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import checkImg from '../../assets/img/check.png';
import grleftarrImg from '../../assets/img/grleftarr.png';
import teambgImg from '../../assets/img/finbg.png';
import { Helmet } from "react-helmet";

function Fintechs() {

    return(
        <>
            <Helmet>
                <title>FinTechs - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'fintechs'}></Menu>
            <div>
                <img src={finImg} alt="About Us" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>FinTechs</h3>
                    </div>
                    <div className="row mt-5">
                        <div className='col-md-12'>
                            <div className='clrbl'>The good news is that you’ve established a firm foothold in home improvement, auto and/or elective medical/dental lending markets, generating hundreds of millions of dollars’ worth of loan applications each month.  The bad news?  You’re challenged in finding reliable lending partners with sufficient capital to fund all those loans through the ups and downs of liquidity cycles and limited balance sheets. </div>
                            <div className='clrbl mt-3'>You’re probably also generating opportunities throughout the FICO spectrum and finding financing partners only interested in skimming the cream off the top. That means you’re leaving tons of opportunity on the table.</div>        
                            <div className='clrbl mt-3'>Diversification of financing sources across the full credit range is critical to sustaining high growth rates. </div>        
                            <div className='clrbl mt-3'>But working directly with multiple lenders has its challenges, in particular when capital markets tighten and/or balance sheets are filled to capacity.  It’s even more uncertain if you’re generating opportunities with a marketplace model or sending leads to lending marketplaces.  Results can vary widely and the consumer experience is out of your control.</div>        
                            <div className='clrbl mt-3'>Let Lendgistics manage financing relationships on your behalf, acting as an extension of your capital markets team.  We work with multiple lenders and institutional buyers via our proprietary Loan Origination System (LOS) platform across the credit spectrum for home improvement, auto and elective medical/dental asset classes. Lendgistics has access to more than $500B of capital through our relationships with lenders and institutional capital sources. Connect to the Lendgistics platform once and access multiple financing relationships to create an immediate multi-source financing strategy.</div>        
                        </div>
                    </div>
                    <h4 className='clrgr inter-bold text-uppercase mt-5'>Lendgistics</h4>
                    <div className='about-us'>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className='clrbl d-flex'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    Identifies, builds and maintains relationships with multiple sources of financing for auto, home improvement and elective medical/dental loans
                                </div>
                                <div className='clrbl d-flex mt-3'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    Connect with Lendgistics once and access multiple financing sources 
                                </div>
                                <div className='clrbl d-flex mt-3'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    Integrates its proprietary Loan Origination System (LOS) platform with your systems via open APIs 
                                </div>
                                <div className='clrbl d-flex mt-3'>
                                    <img src={checkImg} alt="Check" height={20} className="me-2" />
                                    <div>
                                        Applications are sent automatically to Lendgistics for processing -
                                        <div className='mt-3'>
                                            <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                            Verification of all consumer information
                                        </div>
                                        <div className='mt-3'>
                                            <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                            Credit check and underwriting to the lender’s specified criteria
                                        </div>
                                        <div className='mt-3'>
                                            <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                            Conditional approval/denial of applications
                                        </div>
                                        <div className='mt-3'>
                                            <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                            Seeking additional information if needed
                                        </div>
                                        <div className='mt-3'>
                                            <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                            Funding
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 position-relative'>
                                <img src={blankline} alt="About Us" className='first-blank' />
                                <img src={greenline} alt="About Us" className='first-green' />
                                <img src={fintechImg} alt="Lenders"  className='img-fluid'/>
                                <img src={greenline} alt="About Us" className='last-green' />
                                <img src={blankline} alt="About Us" className='last-blank' />
                            </div>
                        </div>
                    </div>
                    <h4 className='clrgr inter-bold mt-5'>The Lendgistics opportunity works best for FinTechs/aggregators with</h4>
                    <div className='clrbl d-flex mt-5'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Home improvement, auto and/or elective medical/dental loans exceeding $10M/month in volume
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        The strategic intent to build dedicated flow programs based on established buy boxes with multiple lenders on a national basis
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Fintechs;