import React from 'react';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';
import buyerImg from '../../assets/img/buyer.png';
import buyersImg from '../../assets/img/buyers.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import checkImg from '../../assets/img/check.png';
import teambgImg from '../../assets/img/buyerbg.png';
import { Helmet } from "react-helmet";

function Buyers() {

    return(
        <>
            <Helmet>
                <title>Buyers - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'buyers'}></Menu>
            <div>
                <img src={buyerImg} alt="About Us" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>Buyers</h3>
                    </div>
                    <div className='about-us'>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className='clrbl'>
                                    Institutional capital investors are always looking for opportunities in consumer asset classes for superior returns, which often means purchasing loan portfolios from financial institutions.  First you have to find the opportunities, then have a team Pour over the loans in the portfolio, decide which meet –your criteria and which don’t, then structure an offer and negotiate terms and conditions. All while competing with multiple other buyers all doing the same thing.  And then rinse and repeat, a time-consuming and inefficient way to deploy capital.
                                </div>
                                <h4 className='clrgr inter-bold mt-3'>There is a better way.</h4>
                                <div className='clrbl mt-3'>Lendgistics works with loan aggregators, FinTechs and lenders on dedicated flow programs built to specified underwriting criteria in the home improvement, auto and elective medical/dental asset classes.  Our lenders are looking to establish long-term forward-flow purchase relationships via our platform with buyers interested in those consumer asset classes.</div>    
                                <div className='clrbl mt-3'>Lendgistics uses its proprietary Loan Origination System (LOS) platform to connect loan sources with lenders and servicers on a full outsourced turnkey basis.  Only those loans that meet the specified buy box are funded.  Lendgistics connects directly with third-party servicers to make the process even more seamless for buyers.</div>
                                <div className='clrbl mt-3'>Rather than evaluating portfolios on a case-by-case basis, dedicated flow programs generate the exact portfolio mix desired by an institutional capital provider on a flow basis.  This is the best of both worlds for an institutional buyer, with the ability to specify exactly the desired loan criteria and source substantial volumes of consumer loans with servicing relationships already in place.</div> 
                            </div>
                            <div className='col-md-6 position-relative'>
                                <img src={blankline} alt="About Us" className='first-blank' />
                                <img src={greenline} alt="About Us" className='first-green' />
                                <img src={buyersImg} alt="Lenders"  className='img-fluid'/>
                                <img src={greenline} alt="About Us" className='last-green' />
                                <img src={blankline} alt="About Us" className='last-blank' />
                            </div>
                        </div>
                    </div>
                    <h4 className='clrgr inter-bold mt-5'>The Lendgistics opportunity works best for institutional buyers with</h4>
                    <div className='clrbl d-flex mt-5'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        The ability to deploy a minimum of $20M/month in capital for purchasing home improvement, auto and/or elective medical/dental loans in a committed forward flow program
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        A well-defined underwriting buy box for approving loans
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Buyers;