import React from 'react';
import { Route, Routes } from "react-router-dom";
import Home from './pages/home/Home';
import NotFound from './pages/home/NotFound';
import About from './pages/about/About';
import Buyers from './pages/buyers/Buyers';
import Fintechs from './pages/fintechs/Fintechs';
import Lenders from './pages/lenders/Lenders';
import Team from './pages/team/Team';
import Technology from './pages/technology/Technology';
import Verticles from './pages/verticles/Verticles';
import Careers from './pages/careers/Careers';
import Contact from './pages/contact/Contact';
import ListContact from './pages/ListContact/ListContact';

const Router = () => {
  return (
    <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/buyers" element={<Buyers/>}/>
        <Route path="/fintechs" element={<Fintechs/>}/>
        <Route path="/lenders" element={<Lenders/>}/>
        <Route path="/teams" element={<Team/>}/>
        <Route path="/technology" element={<Technology/>}/>
        <Route path="/vertical" element={<Verticles/>}/>
        <Route path="/careers" element={<Careers/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path='/listcontacts' element={<ListContact/>}/>
        <Route exact path="*" element={<NotFound />}/>
    </Routes>
  );
}

export default React.memo(Router);