import React from 'react';
import logo from '../logo.png';
// import callIcon from '../assets/img/call.png';
// import mailIcon from '../assets/img/mail.png';
// import fbIcon from '../assets/img/fb.png';
// import twIcon from '../assets/img/tw.png';
// import wpIcon from '../assets/img/wp.png';
// import ybIcon from '../assets/img/yb.png';
// import ldIcon from '../assets/img/ld.png';
import { Link } from 'react-router-dom';

function Footer() {
    return(
        <>
            <div className='footer py-4'>
                <div className='container'>
                    {/* <div className='row'>
                        <div className='col-md-2'>
                            <ul>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Home</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>About</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Lenders</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>FinTechs</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Buyers</Link>
                                </li>
                            </ul>
                        </div> 
                        <div className='col-md-3'>
                            <ul>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Team</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Technology</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Verticles</Link>
                                </li>
                                <li className='my-3'>
                                    <Link className='footer-link' to={'/'}>Contact Us</Link>
                                </li>
                            </ul>
                        </div> 
                        <div className='col-md-3'>
                        </div> 
                        <div className='col-md-4'>
                            <ul>
                                <li className='my-3'>
                                    <img src={callIcon} alt="Phone" height={16}/>
                                    <span className='ms-2 clrg fs14'>Office Phone: (818) 456-5099</span>
                                </li>
                                <li className='my-3'>
                                    <img src={callIcon} alt="Fax" height={16}/>
                                    <span className='ms-2 clrg fs14'>Fax: (818) 456-5094</span>
                                </li>
                                <li className='my-3'>
                                    <img src={mailIcon} alt="Fax" height={16}/>
                                    <span className='ms-2 clrg fs14'>info@Lendgistics.com</span>
                                </li>
                                <li className='mt-5'>
                                    <a className='me-2' href="#sds" target="_blank"><img src={fbIcon} alt="Fax" height={28}/></a>
                                    <a className='me-2' href="#sds" target="_blank"><img src={twIcon} alt="Fax" height={28}/></a>
                                    <a className='me-2' href="#sds" target="_blank"><img src={wpIcon} alt="Fax" height={28}/></a>
                                    <a className='me-2' href="#sds" target="_blank"><img src={ybIcon} alt="Fax" height={28}/></a>
                                    <a className='me-2' href="#sds" target="_blank"><img src={ldIcon} alt="Fax" height={28}/></a>
                                </li>
                            </ul>
                        </div> 
                    </div> */}
                    <div className='text-center'>
                        <img className='logo' src={logo} alt="logo" width={180} />
                    </div>
                    <ul>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/home'}>Home</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/vertical'}>Vertical</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/lenders'}>Lenders</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/fintechs'}>FinTechs</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/buyers'}>Buyers</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/technology'}>Technology</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/teams'}>Team</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/about'}>About Us</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/careers'}>Careers</Link>
                        </li>
                        <li className='my-3'>
                            <Link className='footer-link' to={'/contact'}>Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='footer-down py-3'>
                <div className='clrg text-center fs14'>Copyright © 2022 All rights reserved.</div>
            </div>
        </>
    )
}

export default Footer;