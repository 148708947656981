export const addAuthToHeader = () => {
    const token = "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
    return {
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    }
}

export const addAuthToHeader2 = () => {
  const token = "14FE34B2-9547-43F5-A57C-F0DC7DE81AA9"
  return {
    headers: { 
      Authorization: `Bearer ${token}`, 
      'AppName': 'DbOltp',
      'Content-Type': 'application/json' },
  }
}