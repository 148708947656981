import React, { useState } from 'react';
import axios from 'axios';
import { addAuthToHeader,addAuthToHeader2} from '../../shared/AuthHelper'
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import contactImg from '../../assets/img/contact.png';
import contactgImg from '../../assets/img/contactg.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import teambgImg from '../../assets/img/contactbg.png';
import { Helmet } from "react-helmet";

function Contact() {

    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const submitNow = async (event) => {
        event.preventDefault();
        setLoading(true);
        const { data } = await axios.patch(
        process.env.REACT_APP_BACKEND_URL+'/Models/Save',
        {
            Id: 0,
            Model: 'ContactUs',
            Extensions: null,
            Params: [{
                Name: 'FullName',
                Value: formData.fullName
            }, {
                Name: 'Email',
                Value: formData.email
            }, {
                Name: 'Phone',
                Value: formData.phoneNumber
            },{
                Name: 'Subject',
                Value: formData.subject
            },{
                Name: 'Message',
                Value: formData.message
            }],
        },
        { ...addAuthToHeader() },
        )
        setLoading(false);
        if (data.data.SaveInfo) {
            const { data: result } = await axios.post(
                process.env.REACT_APP_BACKEND_URL+'/v2/Lendgistics/SendContact',
                {
                    Id: data.data.SaveInfo.Id
                },
                { ...addAuthToHeader2() },
            )
            if (result.ContactUs) {
                setFormData({
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: ''
                });
                setFailure(false);
                setSuccess(true);
            } else {
                setFailure(false);
                setSuccess(true);
            }

        } else {
            setSuccess(false);
            setFailure(true);
        }
    }

    return(
        <>
            <Helmet>
                <title>Contact Us - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'contact'} cl></Menu>
            <div>
                <img src={contactImg} alt="Contact Us" className='img-fluid'/>
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>Contact Us</h3>
                    </div>
                </div>
            </div>
            <div className='contact-form py-6 mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 position-relative'>
                            <div className=''>
                                <img src={blankline} alt="Technology" className='first-blank' />
                                <img src={greenline} alt="Technology" className='first-green' />
                                <img src={contactgImg} alt="Technology"  className='img-fluid'/>
                                <img src={greenline} alt="Technology" className='last-green' />
                                <img src={blankline} alt="Technology" className='last-blank' />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <h4 className='clrbl txt-up inter-bold mt-5'>Contact Us Today!</h4>
                            <div className='gr-line ms-0 mb-4'></div>
                            <div className='clrbl'>Please complete the contact form and click the submit button below. We are generally able to respond to inquiries on the same business day as received.</div>
                            <div className='mt-3'>
                                Phone number : <a href='tel:+2139990432' className='clrbl' style={{textDecoration: 'none'}}>(213) 999-0432</a>
                            </div>
                            <div>
                            Email : <a href='mailto:wagius@lendgistics.com' className='clrbl' style={{textDecoration: 'none'}}>wagius@lendgistics.com</a>
                            </div>
                            <h4 className='clrgr txt-up inter-bold mt-4'>SEND A MESSAGE</h4>
                            <div className='gr-line ms-0 mb-3'></div>
                            <form onSubmit={(e) => submitNow(e)}>
                            <div className='row'>
                                <div className='col-md-6 mt-3'>
                                    <input type='text' name='fullName' value={formData.fullName} onChange={(e) => setFormData({...formData, fullName: e.target.value})} className='form-control custom-input' placeholder='Full Name' />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <input type='text' name='email' value={formData.email} onChange={(e) => setFormData({...formData, email: e.target.value})} className='form-control custom-input' placeholder='Email' />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <input type='text' name='subject' value={formData.subject} onChange={(e) => setFormData({...formData, subject: e.target.value})} className='form-control custom-input' placeholder='Subject' />
                                </div>
                                <div className='col-md-6 mt-3'>
                                    <input type='text' name='phoneNumber' value={formData.phoneNumber} onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})} className='form-control custom-input' placeholder='Phone Number' />
                                </div>
                                <div className='col-md-12 mt-3'>
                                    <textarea name='message' value={formData.message} onChange={(e) => setFormData({...formData, message: e.target.value})} className='form-control custom-input' rows={5} placeholder='Message'></textarea>
                                </div>
                                <div className='col-md-4 mt-4'>
                                    <button type="submit" disabled={loading} className="btn btn-theme">{ (loading) ? 'Submitting...' : 'Submit Now' } </button>
                                </div>
                                <div className='col-md-8 mt-4'>
                                    { success ? <div className='clrgr'>Thank you for getting in touch! We'll get back to you very soon.</div>: null }
                                    { failure ? <div className='text-danger'>Failed to Send Your Message.</div>: null }
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Contact;