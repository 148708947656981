import React, { useEffect, useState } from 'react';
import Menu from '../../shared/Menu';
import Modal from 'react-bootstrap/Modal';
import Footer from '../../shared/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Controller, Pagination } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import bgImage from '../../assets/img/bg.png';
import noteIcon from '../../assets/img/note.png';
import bussIcon from '../../assets/img/business.png';
import lrIcon from '../../assets/img/leftarr.png';
import technologyImg from '../../assets/img/technology.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import webinarImg from '../../assets/img/webinar.png';
import suretysImg from '../../assets/img/newsuretys.png';

import RightLogo from '../../assets/img/modal_left_side_logo.png';
import LeftLogo from '../../assets/img/modal_right_side_logo.png';

import closeImg from '../../assets/img/close.png';
import whitePaper from '../../assets/How Lenders can Solve Americas Underserved Auto Buyers Lending Crisis.pdf'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import axios from 'axios';
import { addAuthToHeader,addAuthToHeader2 } from '../../shared/AuthHelper'

function Home() {

    const optionSwiper = {
        modules: [Controller, Autoplay, Pagination],
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: true,
        speed: 2000,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        }
    }

    const [show, setShow] = useState(false);
    const [showWhitePaperModal, setShowWhitePaperModal] = useState(false);
    const [showRecentWebinarModal, setShowRecentWebinarModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [loading, setLoading] = useState(false);
    const webinarUrl = 'https://player.vimeo.com/video/845033480?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
    const handleClose = () => {
        setShow(false);
        // localStorage.setItem("loaded", "1");
    }
    const [personalData, setPersonalData] = useState({
        fullName: '',
        email: '',
        businessType: '',
        businessName: '',
    });

    const [personalData2, setPersonalData2] = useState({
        fullName: '',
        email: '',
        businessType: '',
        businessName: '',
    });
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: ''
    });

    const submitNow = async (event) => {
        event.preventDefault();
        setLoading(true);
        const { data } = await axios.patch(
            process.env.REACT_APP_BACKEND_URL + '/Models/Save',
            {
                Id: 0,
                Model: 'ContactUs',
                Extensions: null,
                Params: [{
                    Name: 'FullName',
                    Value: formData.fullName
                }, {
                    Name: 'Email',
                    Value: formData.email
                }, {
                    Name: 'Phone',
                    Value: formData.phoneNumber
                }, {
                    Name: 'Subject',
                    Value: formData.subject
                }, {
                    Name: 'Message',
                    Value: formData.message
                }],
            },
            { ...addAuthToHeader() },
        )
        setLoading(false);
        if (data.data.SaveInfo) {
            const { data: result } = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/v2/Lendgistics/SendContact',
                {
                    Id: data.data.SaveInfo.Id
                },
                { ...addAuthToHeader2() },
            )
            if (result.ContactUs) {
                setFormData({
                    fullName: '',
                    email: '',
                    phoneNumber: '',
                    subject: '',
                    message: ''
                });
                setFailure(false);
                setSuccess(true);
            } else {
                setFailure(false);
                setSuccess(true);
            }
        } else {
            setSuccess(false);
            setFailure(true);
        }
    }

    const submitWhitePaper = async () => {
        setLoading(true);
        const { data } = await axios.patch(
            process.env.REACT_APP_BACKEND_URL + '/Models/Save',
            {
                Id: 0,
                Model: 'ContactUs',
                Extensions: null,
                Params: [{
                    Name: 'FullName',
                    Value: personalData.fullName
                }, {
                    Name: 'Email',
                    Value: personalData.email
                }, {
                    Name: 'Phone',
                    Value: ""
                }, {
                    Name: 'Subject',
                    Value: "White Paper Request"
                }, {
                    Name: 'Message',
                    Value: `A White Paper request was submitted by ${personalData.fullName} with email at ${personalData.email}. Company is ${personalData.businessName} and Company Type is ${personalData.businessType}`
                }],
            },
            { ...addAuthToHeader() },
        )
        setLoading(false);
        if (data.data.SaveInfo) {
            const { data: result } = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/v2/Lendgistics/SendContact',
                {
                    Id: data.data.SaveInfo.Id
                },
                { ...addAuthToHeader2() },
            )
            if (result.ContactUs) {
                setPersonalData({
                    fullName: '',
                    email: '',
                    businessType: '',
                    businessName: '',
                });
                setFailure(false);
                setSuccess(true);
            } else {
                setFailure(false);
                setSuccess(true);
            }
        } else {
            setSuccess(false);
            setFailure(true);
        }
    }


    const submitWebinar = async () => {
        setLoading(true);
        const { data } = await axios.patch(
            process.env.REACT_APP_BACKEND_URL + '/Models/Save',
            {
                Id: 0,
                Model: 'ContactUs',
                Extensions: null,
                Params: [{
                    Name: 'FullName',
                    Value: personalData2.fullName
                }, {
                    Name: 'Email',
                    Value: personalData2.email
                }, {
                    Name: 'Phone',
                    Value: ""
                }, {
                    Name: 'Subject',
                    Value: "Webinar Request"
                }, {
                    Name: 'Message',
                    Value: `A webinar request was submitted by ${personalData2.fullName} with email at ${personalData2.email}.Company is ${personalData2.businessName} and Company Type is ${personalData2.businessType}`
                }],
            },
            { ...addAuthToHeader() },
        )
        setLoading(false);
        if (data.data.SaveInfo) {
            const { data: result } = await axios.post(
                process.env.REACT_APP_BACKEND_URL + '/v2/Lendgistics/SendContact',
                {
                    Id: data.data.SaveInfo.Id
                },
                { ...addAuthToHeader2() },
            )
            if (result.ContactUs) {
                setPersonalData2({
                    fullName: '',
                    email: '',
                    businessType: '',
                    businessName: '',
                });
                setFailure(false);
                setSuccess(true);
            } else {
                setFailure(false);
                setSuccess(true);
            }
        } else {
            setSuccess(false);
            setFailure(true);
        }
    }

    useEffect(() => {
        // if (!localStorage.getItem("loaded")) {
        setShow(true);
        // }
    }, []);

    return (
        <>
            <Helmet>
                <title>Home - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'home'}></Menu>
            <Swiper {...optionSwiper}>
                <SwiperSlide>
                    <img src={bgImage} alt="BG" />
                    <div className='slide-desc'>
                        <h2 className='inter-black'>Outsourced</h2>
                        <h2 className='inter-bold'> Multi-Channel Lending</h2>
                        <p className='inter-medium'>The Lendgistics platform manages home improvement, auto and elective medical loan sourcing channels for lenders, all on a fully-outsourced basis.</p>
                        <Link type="button" style={{ paddingTop: '8px' }} to={'/contact'} className="btn btn-theme">Contact Us</Link>
                        <div className='mt-4'>
                            {/* <a className="btn btn-theme" href={whitePaper} target='_blank' style={{paddingTop:'8px',paddingLeft:'33px',paddingRight: '33px'}} download>Download our White Paper</a> */}
                            <button className="btn btn-theme" onClick={() => setShowWhitePaperModal(true)} style={{ paddingTop: '8px', paddingLeft: '33px', paddingRight: '33px' }}>Download our White Paper</button>
                        </div>
                    </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                    <img src={bgImage} alt="BG" />
                    <div className='slide-desc'>
                    <h2 className='inter-black'>Outsourced</h2>
                        <h2 className='inter-bold'> Multi-Channel Lending</h2>
                        <p className='inter-medium'>The Lendgistics platform manages home improvement, auto and elective medical loan sourcing channels for lenders, all on a fully-outsourced basis.</p>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </SwiperSlide> */}
            </Swiper>
            <div className='ours py-6'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='d-flex mt-3'>
                                <div className='me-3'>
                                    <img src={bussIcon} alt="Our Business" width={100} />
                                </div>
                                <div className='ms-3'>
                                    <h4 className='clrbl'>OUR BUSINESS</h4>
                                    <div className='clrg'>Lendgistics offers the only end-to-end outsourced solution for lending, from finding loans to underwriting to servicing</div>
                                    <Link className='link mt-3 d-block' to={'/contact'}>Contact Us</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='d-flex mt-3'>
                                <div className='me-3'>
                                    <img src={noteIcon} alt="Our Platform" width={100} />
                                </div>
                                <div className='ms-3'>
                                    <h4 className='clrbl'>OUR PLATFORM</h4>
                                    <div className='clrg'>Lendgistics has developed a proprietary technology platform that manages the entire sourcing channel, from loans to third-party servicing</div>
                                    <Link className='link mt-3 d-block' to={'/contact'}>Contact Us <img src={lrIcon} height={10} alt="Contact Us" /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-5'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={webinarImg} alt="Teams" className='img-fluid' style={{ height: '100px' }} />
                        <h3 className='inter-bold clrbl page-title-text text-uppercase'>Upcoming Webinars</h3>
                    </div>
                    <div className='inter clrbl lh-28 fs14 text-center mt-4'>
                        Unlock the First-Time Car Buyer Marker and Achieve Member Growth While Minimizing Your Load Losses with Surety’s PlusOne Policies
                    </div>
                    <div className='inter clrbl lh-28 fs14 text-center mt-3'>
                        On this webinar, you’ll learn how credit union can build a competitive and safe first-time/Thin-file auto buyer, no-cosigner needed, program to serve this vital underserved market in your community. This program fits perfectly into almost every credit union’s strategy for loan and members acquisition and can help you get increased loan yields while lowering your loan-loss risk. Learn more about this and how it can help your credit union succeed.
                    </div>
                    <div className='d-flex mb-4 mt-4' style={{ justifyContent: 'space-between' }}>
                        <img src={LeftLogo} height={60} />
                        <img src={RightLogo} height={60} />
                    </div>
                    <h4 className='clrbl text-center d-block my-2'>
                        <strong>Did You Miss Our Most Recent WEBINAR?</strong>
                    </h4>
                    <div className='inter lh-28 fs14 mt-2 text-center clrbl'>
                        <p className='mb-0'>Click below to watch the recorded version.</p>
                        <p className='mb-0'>
                            You’ll learn how Suretys and Lendgistics can help your credit union connect with new borrowers to <span style={{ color: '#C1D208' }}>build</span>
                        </p>
                        <p className='mb-0'>
                            and <span style={{ color: '#C1D208' }}>grow</span> a competitive, safe, and profitable First-Time/Thin-File Auto Buyer Program. You’ll also learn
                        </p>
                        <p className='mb-0'>
                            details on how we <span style={{ color: '#C1D208' }}>protect</span> you from loan loss. There is no cost for credit unions to participate in the
                        </p>
                        <p className='mb-0'>
                            program, and no cost to credit unions in securing the loan protection coverage.
                        </p>
                        <p className='mb-0'>
                            Learn more about this opportunity and how it can help your credit union succeed while also greatly
                        </p>
                        <p className='mb-0'>
                            benefiting the consumers you serve.
                        </p>
                    </div>
                    {/* <div className='text-center mt-3'>
                            <a rel="noreferrer" target={'_blank'} className='inter clrbl lh-28 fs14 text-decoration-none' href={webinarUrl}>
                                <button type="button"  className="btn btn-theme">Watch our most recent Webinar</button>
                            </a>
                        </div> */}
                    <div className='text-center mt-3'>
                        <button type="button" onClick={() => setShowRecentWebinarModal(true)} className="btn btn-theme">Watch our most recent Webinar</button>
                    </div>
                    <div className='inter lh-28 fs14 mt-2 text-center clrbl'>
                        <p className='mb-0'>Want to learn even more?</p>
                    </div>
                    <h4 className='clrbl text-center d-block my-2'>
                        <strong>Download our FREE independently developed White Paper</strong>
                    </h4>
                    <div className='inter lh-28 fs14 mt-2 text-center clrbl'>
                        <p className='mb-0'>“How Lenders can Solve America’s Undeserved Auto Buyers’ Lending Crisis”</p>
                        <p className='mb-0'><i>A white paper Research Project written by Michael G. Hales, The Hales Connection</i></p>
                        <p className='mb-0'>Click this link to download our white paper on the emerging First-Time/Thin-File underserved market in </p>
                        <p className='mb-0'>your community and how our program helps deliver significant benefits to consumers</p>
                        <p className='mb-0'>and the lenders that choose to serve them.</p>
                    </div>
                    <div className='text-center mt-3'>
                        <button type="button" onClick={() => setShowWhitePaperModal(true)} className="btn btn-theme">Download our White Paper</button>
                    </div>
                </div>
            </div>
            <div className='contact-form py-5'>
                <div className='container'>
                    <div className='about-us'>
                        <div className='row'>
                            <div className='col-md-6 position-relative'>
                                <h4 className='clrbl txt-up inter-bold'>Contact Us Today!</h4>
                                <div className='gr-line ms-0 mb-4'></div>
                                <div className=''>
                                    <img src={blankline} alt="Technology" className='first-blank' style={{ top: '0px' }} />
                                    <img src={greenline} alt="Technology" className='first-green' style={{ top: '0px' }} />
                                    <img src={technologyImg} alt="Technology" className='img-fluid' />
                                    <img src={greenline} alt="Technology" className='last-green' style={{ bottom: '-20px' }} />
                                    <img src={blankline} alt="Technology" className='last-blank' style={{ bottom: '-20px' }} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <form onSubmit={(e) => submitNow(e)}>
                                    <div className='row'>
                                        <div className='col-md-6 mt-3'>
                                            <input type='text' name='fullName' value={formData.fullName} onChange={(e) => setFormData({ ...formData, fullName: e.target.value })} className='form-control custom-input' placeholder='Full Name' />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <input type='text' name='email' value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} className='form-control custom-input' placeholder='Email' />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <input type='text' name='subject' value={formData.subject} onChange={(e) => setFormData({ ...formData, subject: e.target.value })} className='form-control custom-input' placeholder='Subject' />
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                            <input type='text' name='phoneNumber' value={formData.phoneNumber} onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })} className='form-control custom-input' placeholder='Phone Number' />
                                        </div>
                                        <div className='col-md-12 mt-3'>
                                            <textarea name='message' value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} className='form-control custom-input' rows={5} placeholder='Message'></textarea>
                                        </div>
                                        <div className='col-md-4 mt-4'>
                                            <button type="submit" disabled={loading} className="btn btn-theme">{(loading) ? 'Submitting...' : 'Submit Now'} </button>
                                        </div>
                                        <div className='col-md-8 mt-3 text-end'>
                                            Phone number : <a href='tel:+2139990432' className='clrbl' style={{ textDecoration: 'none' }}>(213) 999-0432</a>
                                            <br />
                                            Email : <a href='mailto:wagius@lendgistics.com' className='clrbl' style={{ textDecoration: 'none' }}>wagius@lendgistics.com</a>
                                        </div>
                                        <div className='col-md-12 mt-4'>
                                            {success ? <div className='clrgr'>Thank you for getting in touch! We'll get back to you very soon.</div> : null}
                                            {failure ? <div className='text-danger'>Failed to Send Your Message.</div> : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className='postion-relative'>
                        <div style={{
                            position: 'absolute',
                            right: '0px',
                            top: '0px',
                            cursor: 'pointer'
                        }} onClick={handleClose} >
                            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Menu / Close_MD">
                                    <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </g>
                            </svg>
                        </div>
                        <div className='d-flex mb-4' style={{ justifyContent: 'space-between' }}>
                            <img src={LeftLogo} height={60} />
                            <img src={RightLogo} height={60} />
                        </div>
                        <h4 className='clrbl text-center d-block my-2'>
                            <strong>Did You Miss Our Most Recent WEBINAR?</strong>
                        </h4>
                        <div className='inter lh-28 fs14 mt-2 text-center clrbl'>
                            <p className='mb-0'>Click below to watch the recorded version.</p>
                            <p className='mb-0'>
                                You’ll learn how Suretys and Lendgistics can help your credit union connect with new borrowers to <span style={{ color: '#C1D208' }}>build</span>
                            </p>
                            <p className='mb-0'>
                                and <span style={{ color: '#C1D208' }}>grow</span> a competitive, safe, and profitable First-Time/Thin-File Auto Buyer Program. You’ll also learn
                            </p>
                            <p className='mb-0'>
                                details on how we <span style={{ color: '#C1D208' }}>protect</span> you from loan loss. There is no cost for credit unions to participate in the
                            </p>
                            <p className='mb-0'>
                                program, and no cost to credit unions in securing the loan protection coverage.
                            </p>
                            <p className='mb-0'>
                                Learn more about this opportunity and how it can help your credit union succeed while also greatly
                            </p>
                            <p className='mb-0'>
                                benefiting the consumers you serve.
                            </p>
                        </div>
                        <div className='text-center mt-3'>
                            <button type="button" className="btn btn-theme" onClick={() => { handleClose(); setShowRecentWebinarModal(true) }}>Watch our most recent Webinar</button>
                        </div>
                        <div className='inter lh-28 fs14 mt-2 text-center clrbl'>
                            <p className='mb-0'>Want to learn even more?</p>
                        </div>
                        <h4 className='clrbl text-center d-block my-2'>
                            <strong>Download our FREE independently developed White Paper</strong>
                        </h4>
                        <div className='inter lh-28 fs14 mt-2 text-center clrbl'>
                            <p className='mb-0'>“How Lenders can Solve America’s Undeserved Auto Buyers’ Lending Crisis”</p>
                            <p className='mb-0'><i>A white paper Research Project written by Michael G. Hales, The Hales Connection</i></p>
                            <p className='mb-0'>Click this link to download our white paper on the emerging First-Time/Thin-File underserved market in </p>
                            <p className='mb-0'>your community and how our program helps deliver significant benefits to consumers</p>
                            <p className='mb-0'>and the lenders that choose to serve them.</p>
                        </div>
                        <div className='text-center mt-3'>
                            <button type="button" onClick={() => { handleClose(); setShowWhitePaperModal(true) }} className="btn btn-theme">Download our White Paper</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showWhitePaperModal}
                onHide={() => { setShowWhitePaperModal(false) }}
                backdrop="static"
                size="md"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Personal Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            Enter your information here to download our White Paper.
                        </div>
                        <div className='col-md-12 mb-2'>
                            <select
                                id="businessTypeSelect"
                                name="businessType"
                                value={personalData.businessType}
                                onChange={(e) => setPersonalData({ ...personalData, businessType: e.target.value })}
                                className="form-control custom-input"
                                placeholder='Select Business Type'
                                required
                            >
                                <option value="" disabled>Select a business type</option>
                                <option value="Credit Union">Credit Union</option>
                                <option value="Bank">Bank</option>
                                <option value="Finance Company">Finance Company</option>
                                <option value="Other Loan Originator">Other Loan Originator </option>
                                <option value="Consumer Advocate">Consumer Advocate</option>
                                <option value="Other">Other </option>
                            </select>
                        </div>

                        <div className='col-md-12 mb-2'>
                        <input
                                type='text'
                                name='businessName'
                                value={personalData.businessName}
                                onChange={(e) => setPersonalData({ ...personalData, businessName: e.target.value })}
                                className='form-control custom-input'
                                placeholder='Business Name' />
                        </div>

                        <div className='col-md-12 mb-2'>
                            <input
                                type='text'
                                name='fullName'
                                value={personalData.fullName}
                                onChange={(e) => setPersonalData({ ...personalData, fullName: e.target.value })}
                                className='form-control custom-input'
                                placeholder='Full Name' />
                        </div>
                        <div className='col-md-12 mb-4'>
                            <input
                                type='text'
                                name='email'
                                value={personalData.email}
                                onChange={(e) => setPersonalData({ ...personalData, email: e.target.value })}
                                className='form-control custom-input'
                                placeholder='Email' />
                        </div>
                        <div className='col-md-12 mb-2 text-center'>
                            <button className="btn btn-theme" onClick={() => { setShowWhitePaperModal(false); submitWhitePaper(); }} disabled={!personalData.fullName || !personalData.email} style={{ paddingTop: '8px', paddingLeft: '33px', paddingRight: '33px' }}>
                                <a href={whitePaper} rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }} target='_blank' download>Download our White Paper</a>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showRecentWebinarModal}
                onHide={() => { setShowRecentWebinarModal(false) }}
                backdrop="static"
                size="md"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Personal Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-12 mb-2'>
                            Enter your information here to watch the most recent Webinar.
                        </div>
                        <div className='col-md-12 mb-2'>
                            <select
                                id="businessTypeSelect"
                                name="businessType"
                                value={personalData2.businessType}
                                onChange={(e) => setPersonalData2({ ...personalData2, businessType: e.target.value })}
                                className="form-control custom-input"
                                placeholder='Select Business Type'
                                required
                            >
                                <option value="" disabled>Select a business type</option>
                                <option value="Credit Union">Credit Union</option>
                                <option value="Bank">Bank</option>
                                <option value="Finance Company">Finance Company</option>
                                <option value="Other Loan Originator">Other Loan Originator </option>
                                <option value="Consumer Advocate">Consumer Advocate</option>
                                <option value="Other">Other </option>
                            </select>
                        </div>

                        <div className='col-md-12 mb-2'>
                        <input
                                type='text'
                                name='businessName'
                                value={personalData2.businessName}
                                onChange={(e) => setPersonalData2({ ...personalData2, businessName: e.target.value })}
                                className='form-control custom-input'
                                placeholder='Business Name' />
                        </div>
                        <div className='col-md-12 mb-2'>
                            <input
                                type='text'
                                name='fullName'
                                value={personalData2.fullName}
                                onChange={(e) => setPersonalData2({ ...personalData2, fullName: e.target.value })}
                                className='form-control custom-input'
                                placeholder='Full Name' />
                        </div>
                        <div className='col-md-12 mb-4'>
                            <input
                                type='text'
                                name='email'
                                value={personalData2.email}
                                onChange={(e) => setPersonalData2({ ...personalData2, email: e.target.value })}
                                className='form-control custom-input'
                                placeholder='Email' />
                        </div>
                        <div className='col-md-12 mb-2 text-center'>
                            <button className="btn btn-theme" onClick={() => { setShowRecentWebinarModal(false); submitWebinar() }} disabled={!personalData2.fullName || !personalData2.email} style={{ paddingTop: '8px', paddingLeft: '33px', paddingRight: '33px' }}>
                                <a href={webinarUrl} style={{ color: 'white', textDecoration: 'none' }} target='_blank' download>Watch our most recent Webinar</a>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer></Footer>
        </>
    )
}

export default Home;