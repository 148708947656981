import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import aboutImg from '../../assets/img/about.png';
import aboutSideImg from '../../assets/img/aboutsd.png';
import waltAgius from '../../assets/img/wallr.png';
import davidP from '../../assets/img/db.png';
import davidJ from '../../assets/img/df.png';
import steve from '../../assets/img/sr.png';
import waltAgiusImg from '../../assets/img/walln.png';
import davidPImg from '../../assets/img/david.png';
import davidJImg from '../../assets/img/davidf.png';
import steveImg from '../../assets/img/steve.png';
import glarr from '../../assets/img/glarr.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import closeImg from '../../assets/img/close.png';
import gmailImg from '../../assets/img/gmail.png';
import teambgImg from '../../assets/img/aboutbg.png';
import { Helmet } from "react-helmet";

function About() {

    const [show, setShow] = useState(false);
    const [member, setMember] = useState({});
    const handleClose = () => setShow(false);
    const showProfile = (user) => {
        setMember(user);
        setShow(true);
    }
    const teams = [{
        name: "Walt Agius",
        role: "Founder",
        image: waltAgius,
        picture: waltAgiusImg,
        email: "wagius@lendgistics.com",
        info: "<div class='fs14'>Walt Agius is the founder and Managing Member/Board Chair of Lendgistics, LLC (dba CU Lending Edge) and also the founder and CEO of a FinTech Loan Origination software platform called Lendsys.</div><div class='fs14 mt-3'>Walt has over 30 years of experience as CEO, along with being a Consultant working with and for credit unions, finance companies, CUSO’s, auto dealerships, and developed multiple successful Entrepreneurship companies. He continues to be a frequent speaker and presenter for National Financial Marketplace related conferences.</div>"
    }, { 
        name: "Steve Ryerson",
        role: "Owner",
        image: steve,
        picture: steveImg,
        email: "sryerson@sjr4advisors.com",
        info: "<div class='fs14'>Steve is a Financial Services executive with proven leadership and entrepreneurial experience. He has over 40 years’ experience as a C-Suite leader with very significant responsibilities and oversight for P & L’s, revenue generation, product creation, global partnership development, market share and acquisitions.Steve spent the early part of his career on Wall St. where for over 20+ years he had C-Level roles at several firms. His experience spans institutional banking and finance as well as government securities trading and brokering. After a successful career on Wall St., Steve pivoted to the retail financial services sector and joined the United Nations Federal Credit Union (UNFCU) in 2003.</div><div class='fs14 mt-3'>Steve served UNFCU for almost 16 years where he held the roles of President & CEO of UNFCU Financial Services and UNFCU Advisors and as UNFCU’s Chief Business Development Officer. Steve grew UNFCU’s non-banking financial services platform to serve United Nations staff and their families around the globe.</div><div class='fs14 mt-3'>After 16 years of service at the United Nations Federal Credit Union, Steve founded SJR IV Advisors to fulfill his vision and passion to help financial service companies grow revenue and profits by helping them deliver value driven financial products and services to the clients and members they serve. </div><div class='fs14 mt-3'>In 2021, seeing a strategic fit with Lendgistics, Steve purchased a significant equity stake in the company and assume the role of Chief Revenue and Business Development Officer.</div>"
    }, { 
        name: "David Frankil",
        role: "Owner",
        image: davidJ,
        picture: davidJImg,
        email: "dcfrankil@gmail.com",
        info: "<div class='fs14'>David has a diverse, forty year career spanning C-level positions at companies and associations, management consulting and investment banking, venture-funded startups and government affairs at the national level.</div><div class='fs14 mt-3'>David has been President, CrossState Solutions, the for-profit subsidiary of the CrossState Credit Union Association; Pres/CEO of the New Jersey Credit Union League the State association for credit unions in New Jersey; President, Burns-Fazzi, Brock & Assoc (now part of Gallagher Benefits) a firm specializing in executive benefits for credit union C-level executives; and President, NAFCU Services Corp, the for-profit subsidiary of the National Association of Federally Insured Credit Unions.</div><div class='fs14 mt-3'>Prior to that, David helped companies of all sizes as a management consult and investment banker as Managing Director–Washington DC for Gates & Company; founded and ran a venture-funded startup called Virtual Compliance; and had a full career in government affairs at the national level with the National Association of Manufacturers, the American Furniture Manufacturers Association, and Champion International Corp (now part of International Paper). David is a Wharton MBA. David lives in New Jersey about fifty yards from the beach with his wife Allison and rescue dog Pokey, and in his spare time enjoys cycling along the coast, playing bridge and chess and building way-too-complicated spreadsheets.</div>"
    },  { 
        name: "David Berver",
        role: "Owner",
        image: davidP,
        picture: davidPImg,
        email: "dberver@lendgistics.com",
        info: "<div class='fs14'>Extensive Credit Union background in all areas of operations, but primarily in lending. David’s 20 years plus of Credit Union experience covers managing Branch operations, along with responsibilities of developing and managing an Auto Dealership CUSO. He has an extensive background in all facets of indirect and direct lending, from underwriting to program software design.  His full range of experience and background brings both the lender and dealer’s perspective.</div><div class='fs14 mt-3'>Enjoys long walks on the beach, if it’s not sandy.  Enjoys to go to Disney, when there are no long lines.  Enjoys Summer, so long as there is air conditioning.  Enjoys to put on English subtitles, on English speaking movies.  Enjoys doing meditation, when he’s sleeping.</div>"
    }]

    return(
        <>
            <Helmet>
                <title>About Us - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'about'}></Menu>
            <div>
                <img src={aboutImg} alt="About Us" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>About</h3>
                    </div>
                    <div className='about-us'>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className='clrbl'>Lendgistics connects FinTechs and other consumer loan aggregators that have loans and need capital with lenders and asset managers that have capital and need loans. Our primary focus is on home improvement, auto and elective medical/dental asset classes.</div>
                                <div className='clrbl mt-3'>More than just a broker, Lendgistics uses its proprietary technology platform to manage each channel end-to-end on a turnkey, outsourced basis - from loan sourcing relationships and processing to underwriting and integration with third party servicers.</div>        
                                <div className='clrbl mt-3'>Lenders and institutional capital sources connect to Lendgistics once and can quickly deploy capital into multiple consumer asset classes and opportunities. The same is true for FinTechs/aggregators - connect to the Lendgistics platform once and access multiple capital partners. </div>        
                                <div className='clrbl mt-3'>Originally founded by Walt Agius in 2011 with a focus on indirect auto lending, Lendgistics today has evolved to a true outsourced multi-channel lending strategy for consumer loans, from sourcing to underwriting and third-party servicing.</div>        
                            </div>
                            <div className='col-md-6 position-relative'>
                                <img src={blankline} alt="About Us" className='first-blank' />
                                <img src={greenline} alt="About Us" className='first-green' />
                                <img src={aboutSideImg} alt="Our Business"  className='img-fluid'/>
                                <img src={greenline} alt="About Us" className='last-green' />
                                <img src={blankline} alt="About Us" className='last-blank' />
                            </div>
                        </div>
                    </div>    
                    <h4 className='clrgr inter-bold text-uppercase mt-5'>Board of Directors</h4>
                    <div className="row mt-5">
                    {
                        teams.map((user, key) => {
                            return <div key={key} className='col-md-3 text-center'>
                                <img src={user.image} alt={user.name} className='img-fluid about-user' />
                                <h3 className='clrbl inter-bold mt-2'>{user.name}</h3>
                                <div className='clrgr d-block'>{user.role}</div>
                                <div className='mt-2'>
                                    <div className='text-uppercase clrlg text-none view-profile pointer' onClick={() => showProfile(user)}> Profile <img src={glarr} alt="Walt Agius" className='img-fluid' /></div>
                                </div>
                            </div>
                        })
                    }
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size="xl"
                keyboard={false}
                centered
            >
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-4'>
                            <img src={member.picture} alt={member.name} className='img-fluid' />
                        </div>
                        <div className='col-md-8 position-relartive'>
                            <div className='clrbl text-uppercase fs14 pointer close-profile' onClick={handleClose}><b>Close Profile</b> <img src={closeImg} alt='Close' height={24} /></div>
                            <div className='clrgr fs14'>{member.role}</div>
                            <h4 className='clrbl'>{member.name}</h4>
                            <div className='clebl' dangerouslySetInnerHTML={{__html: member.info}}></div>
                            <div className='mt-3 fs14'>
                                <img src={gmailImg} alt='Close' height={16} /> {member.email}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default About;