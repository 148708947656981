import React from 'react';
import Menu from '../../shared/Menu';
import { Link } from 'react-router-dom';
// import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import vertImg from '../../assets/img/vert.png';
import verticleImg from '../../assets/img/verticle.png';
import vdigitalImg from '../../assets/img/vdigital.png';
import suretysImg from '../../assets/img/newsuretys.png';
import vautoImg from '../../assets/img/vauto.png';
import teambgImg from '../../assets/img/vertbg.png';
import { Helmet } from "react-helmet";

function Verticles() {

    return(
        <>
            <Helmet>
                <title>Vertical - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'Vertical'}></Menu>
            <div>
                <img src={vertImg} alt="Vertical" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>Vertical</h3>
                    </div>
                    <div className='mt-5'>
                    <div className='inter-bold'>Lendgistics clients have access to consumer loans in four different areas of consumer asset classes – home improvement, auto, Suretys first time buyer program, and elective medical/dental.</div>
                    <div className="row mt-5">
                        <div className="col-md-6 text-center px-3 position-relative">
                            <img src={verticleImg} alt="Verticles" className='img-fluid' />
                            <h5 className='clrgr inter-bold text-uppercase mt-5'>Home Improvement </h5>
                            <div className='inter clrbl lh-28 fs14 mt-3'>
                                The market for home improvement loans is approaching $500B per year, and rapid growth shows no signs of abating.  Much of the opportunity is at the higher end of the credit spectrum, featuring homeowners in the higher FICO ranges.  Lendgistics sources loans from FinTech and other loan aggregators that collectively reach more than 170,000 home improvement contractors across the country. 
                            </div>
                            <div className='vr-line'></div>
                        </div>
                        <div className="col-md-6 text-center px-3">
                            <img src={vautoImg} alt="Verticles" className='img-fluid' />
                            <h5 className='clrgr inter-bold text-uppercase mt-5'>Auto </h5>
                            <div className='inter clrbl lh-28 fs14 mt-3'>
                                Auto finance is estimated to exceed $200B in 2022, with continued strong growth to 2023. Opportunities exist across all credit segments. Lendgistics generates auto loans via its sourcing relationships with more than 10,000 auto dealers. The Lendgistics platform is integrated with DealerTrack, RouteOne, Credco, Lending Tree, Open Lending, Vantage DMS, and others for maximum efficiency and market reach. 
                            </div>
                        </div>
                        <div className="col-md-6 text-center px-3 position-relative mt-5">
                            <img src={vdigitalImg} alt="Verticles" className='img-fluid' />
                            <h5 className='clrgr inter-bold text-uppercase mt-5'>Elective Medical/Dental  </h5>
                            <div className='inter clrbl lh-28 fs14 mt-3'>
                                The elective medical and dental financing market is expected to exceed a combined $100B in 2022, with opportunities primarily in the higher end of the credit ranges. Lendgistics sources loans from FinTechs and other loan aggregators that work directly with medical and dental providers to provide a range of patient financing options.
                            </div>
                            <div className='vr-line'></div>
                        </div>
                        <div className="col-md-6 text-center px-3 mt-5">
                            <img src={suretysImg} alt="Verticles" className='img-fluid' />
                            <h5 className='clrgr inter-bold text-uppercase mt-5'>Suretys  </h5>
                            <div className='inter clrbl lh-28 fs14 mt-3'>
                                Suretys offers PlusOne policies for first-time car buyers with limited credit history. Suretys protects lenders from loan default risk while enabling lenders to close loans to an underserved market profitably. Suretys proprietary risk algorithm allows the company to qualify borrowers without relying on their credit score.
                            </div>
                        </div>
                    </div> 
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                    </div>    
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Verticles;