import React from 'react';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';
import lendImg from '../../assets/img/lend.png';
import lenderSideImg from '../../assets/img/lenders.png';
import lendleftImg from '../../assets/img/lendleft.png';
import lendrightImg from '../../assets/img/lendright.png';
import blankline from '../../assets/img/blankline.png';
import greenline from '../../assets/img/greenline.png';
import blueline from '../../assets/img/blueline.png';
import checkImg from '../../assets/img/check.png';
import grleftarrImg from '../../assets/img/grleftarr.png';
import blleftarrImg from '../../assets/img/blleftarr.png';
import teambgImg from '../../assets/img/lendbg.png';
import { Helmet } from "react-helmet";

function Lenders() {

    return(
        <>
            <Helmet>
                <title>Lenders - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'lenders'}></Menu>
            <div>
                <img src={lendImg} alt="About Us" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={teambgImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>Lenders</h3>
                    </div>
                    <div className='about-us'>
                        <div className="row">
                            <div className='col-md-6'>
                                <div className='clrbl'>Building a multi-channel lending strategy can be challenging for even the most sophisticated financial institution.  Every lender has traditional/existing/organic consumer lending programs in areas like mortgages, mortgage refis and HELOCs.  Expanding into other consumer asset classes is an important part of a diversified lending growth and effective ALM/ALL strategy. </div>
                                <div className='clrbl mt-3'>But the competition for auto, home improvement and elective medical/dental loans makes it difficult to either source them directly or to establish sourcing relationships with loan aggregators.  Even if you build relationships with loan aggregators and FinTechs, few sources can truly deliver meaningful volume.  As a result, pursuing this strategy on your own also means making risky and expensive investments in business development and lending infrastructure that may never pay off.  </div>        
                                <div className='clrbl mt-3'>Lendgistics does all this and more for its lender clients, acting as an extension of their internal team with a fully outsourced model, from sourcing to servicing.  Lenders connect to Lendgistics once and can access loans from multiple sources and asset classes to create an immediate multi-channel lending strategy.  Best of all, Lendgistics only gets paid when a loan is successfully funded, mitigating risk by turning a fixed cost into a variable cost. </div>        
                            </div>
                            <div className='col-md-6 position-relative'>
                                <img src={blankline} alt="About Us" className='first-blank' />
                                <img src={blueline} alt="About Us" className='first-green' />
                                <img src={lenderSideImg} alt="Lenders"  className='img-fluid'/>
                                <img src={blueline} alt="About Us" className='last-green' />
                                <img src={blankline} alt="About Us" className='last-blank' />
                            </div>
                        </div>
                    </div>    
                    <h4 className='clrgr inter-bold text-uppercase mt-5'>Lendgistics</h4>
                    <div className="row about-us">
                        <div className='col-md-6 bg-white position-relative'>
                            <img src={blankline} alt="Lenders" className='left-first-blank' />
                            <img src={greenline} alt="Lenders" className='left-first-green' />
                            <img src={lendleftImg} alt="Lenders"  className='img-fluid lend-left' />
                            <img src={lendrightImg} alt="Lenders"  className='img-fluid lend-right'/>
                            <img src={greenline} alt="Lenders" className='left-last-green' />
                            <img src={blankline} alt="Lenders" className='left-last-blank' />
                        </div>
                        <div className='col-md-6'>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                Identifies, builds and maintains relationships with multiple sources of home improvement, auto and elective medical/dental loans
                            </div>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                Integrates its proprietary Loan Origination System (LOS) platform with multiple loan sources
                            </div>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                <div>
                                    Receives and processes applications -
                                    <div className='mt-3'>
                                        <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                        Verifying all consumer information
                                    </div>
                                    <div className='mt-3'>
                                        <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                        Checking credit and underwriting to the lender’s specified criteria
                                    </div>
                                    <div className='mt-3'>
                                        <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                        Conditionally approving/denying applications
                                        <div className='ps-5 mt-3'>
                                            <img src={grleftarrImg} alt="Check" height={8} className="" />
                                            <img src={blleftarrImg} alt="Check" height={8} className="me-2" />
                                            Lenders only receive those loans that meet their criteria
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                        Seeking additional information if needed
                                    </div>
                                </div>
                            </div>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                Funds the loan using the lender’s own loan documents
                            </div>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                Sends the lender a daily funding file
                            </div>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                Pull funds from an account and delivers via ACH
                            </div>
                            <div className='clrbl d-flex mt-3'>
                                <img src={checkImg} alt="Check" height={20} className="me-2" />
                                Integrates with outsourced third-party servicers (if desired)
                            </div>
                        </div>
                    </div>
                    <h4 className='clrgr inter-bold mt-5'>The Lendgistics opportunity works best for lenders with</h4>
                    <div className='clrbl d-flex mt-5'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        The ability and strategic intent to grow consumer lending programs to scale on a national basis
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        A balance sheet that can accommodate volumes of $5M-$100M/month and more
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        And/or an interest in generating loans and selling on forward flow basis to our institutional buyers
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Lenders;