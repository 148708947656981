import React from 'react';
import Menu from '../../shared/Menu';
// import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import Footer from '../../shared/Footer';
import careersImg from '../../assets/img/careers.png';
import checkImg from '../../assets/img/check.png';
import cImg from '../../assets/img/ct.png';
import grleftarrImg from '../../assets/img/grleftarr.png';
import { Helmet } from "react-helmet";

function Careers() {

    return(
        <>
            <Helmet>
                <title>Careers - Lendgistics</title>
            </Helmet>
            {/* <Header></Header> */}
            <Menu menuName={'careers'}></Menu>
            <div>
                <img src={careersImg} alt="About Us" className='img-fluid' />
            </div>
            <div className='teams py-6'>
                <div className='container'>
                    <div className='text-center position-relative'>
                        <img src={cImg} alt="Teams" className='img-fluid' style={{height: '100px'}}  />
                        <h3 className='inter-bold clrbl page-title-text'>Careers</h3>
                    </div>
                    <h4 className='clrgr inter-bold mt-5 text-uppercase'>Position: <span className='clrbl'>Underwriter</span></h4>
                    {/* <h5 className='clrgr inter-bold mt-1 text-uppercase'>Salary Range: ($50,000 - $70,000)</h5> */}
                    <div className='clrgr inter-bold mt-1'>General Purpose:</div>
                    <div className='clrbl d-flex mt-5'>
                        Responsible for deciding whether a borrower's loan application is approved or not by evaluating risk presented by the entire loan application.
                    </div>
                    <h5 className='clrgr inter-bold mt-3 mb-3 text-uppercase'>Essential Skills:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Compliance
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Mortgage loans
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Auto Loans
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Risk Management
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Analytical Skills
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Problem – solving skills.
                    </div>
                    <h5 className='clrgr inter-bold mt-3 mb-3 text-uppercase'>Roles & Responsibilities:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Review and verify loan applications and supporting documentation
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Define the terms and conditions of the total loan.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Review loan documentation and vendor reports to identify signs of fraudulent activity
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Make loan eligibility decisions and approving or rejecting applications
                    </div>
                    <h5 className='clrgr inter-bold mt-3 mb-3 text-uppercase'>Day to Day Duties:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Communicate with loan officers and management to maintain a case-by-case follow-up and ensure timely decisions are made.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Request additional information beyond the application to ensure thorough and accurate evaluations (when necessary)
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Offer recommendations on whether to approve an application or deny.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Analyze loan risk
                    </div>
                    <h5 className='clrgr inter-bold mt-3 mb-3 text-uppercase'>Qualifications:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Associates Degree Preferred or
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        High School Diploma or Equivalent required.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Minimum of 2 years of residential or consumer loan underwriting experience
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Knowledge of all mortgage and consumer lending regulations.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Excellent communication skills.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Computer and system skills.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Proficient in Microsoft Office.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Ability to prioritize and meet deadlines.
                    </div>
                    <h4 className='clrgr inter-bold mt-5 text-uppercase'>Position: <span className='clrbl'>Loan Validator</span></h4>
                    <div className='clrbl d-flex mt-2'>
                        As a loan validation specialist (LVS) you will authenticate information provided for the purposes of funding consumer, long-term personal loans.
                    </div>
                    <h5 className='clrgr inter-bold mt-3 mb-3 text-uppercase'>You will engage in daily work activities such as:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Documenting/notating account information
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Resolving conflicts & negotiating with others
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Obtaining confidential information
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Communicating with your supervisors and peers in a positive fashion
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Evaluating information to determine compliance with standards
                    </div>
                    <div className='clrbl d-flex mt-4'>
                        Responsibilities include, but are not limited to, working in a team environment to meet monthly funding goals.
                    </div>
                    <div className='clrbl d-flex mt-2'>
                        Initiating contact and following up with leads while providing exceptional customer service.
                    </div>
                    <div className='clrbl d-flex mt-2'>
                        Communicate using concentration, gestures of your engagement, feedback, deferred judgement, and appropriate response techniques.
                    </div>
                    <div className='clrbl d-flex mt-2'>
                        Excellent command of financial literacy for articulating loan terms, payment schedules, and overcoming objections verbally through phone interactions.
                    </div>
                    <div className='clrbl d-flex mt-2'>
                        As a loan verifications specialist (LVS) you will be utilized through active learning, where you will be fully engaged and thoughtful about on-going training topics.
                    </div>
                    <div className='clrbl d-flex mt-2'>
                        Your ability to use both negative and positive review from your superior to your advantage is critical.
                    </div>
                    <div className='clrgr inter-bold mt-4'>Excellent time management</div>
                    <h5 className='clrgr inter-bold mt-3 mb-3 text-uppercase'>Minimum Qualifications:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        HS/GED completed with at least six (6) months of relevant and recent call center customer service, call center sales, or call center technical support or financial accounts/loan processing or clerkship, lending, sales administration, personal banking, or teller required.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Must possess working knowledge of exceptional customer and personal service principals.
                    </div>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        <div>
                            Superior technology orientation preferred as you will be toggling between various applications
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Multiple browser windows
                            </div>
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Cloud-based system
                            </div>
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Banking accounts third party verification system
                            </div>
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Document management software
                            </div>
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Search engines
                            </div>
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Office instant messenger
                            </div>
                            <div className='mt-3'>
                                <img src={grleftarrImg} alt="Check" height={16} className="me-2" />
                                Strong typing skills will be utilized.
                            </div>
                        </div>
                    </div>
                    <h5 className='clrgr inter-bold mt-4 mb-3 text-uppercase'>Preferred Qualifications:</h5>
                    <div className='clrbl d-flex mt-3'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Two (2) or more years of call center sales/call center customer service.
                    </div>
                    <div className='clrgr inter-bold mt-3 mb-3 ps-5'>OR</div>
                    <div className='clrbl d-flex'>
                        <img src={checkImg} alt="Check" height={20} className="me-2" />
                        Two (2) years or more of personal consumer loan/financial accounts sales/coordination/processing.
                    </div>
                    <div className='clrgr mt-5 mb-3'>
                        If interested in applying please email your resume to <a href="mailto:anthonya@lendgistics.com">anthonya@lendgistics.com</a>
                    </div>
                    <div className='mt-5'>
                        <span className='pe-3'>interested in more information?</span>
                        <Link type="button" to={'/contact'} className="btn btn-theme">Contact Us</Link>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </>
    )
}

export default Careers;